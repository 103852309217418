<template>
  <a-modal style="top: 8px;"
           :width="1000"
           v-model="visible"
           :footer="null"
           :title="dialogTitle"
           :maskClosable="false">

    <!-- 查询表单 -->
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:8}" :wrapper-col="{xs:16}">
      <a-row :gutter="8">
        <a-col :xxl="6" :xl="6" :md="8" :sm="12">
          <a-form-item label="操作类型">
            <a-select placeholder="请选择操作类型" v-model="searchData.operationType">
              <a-select-option :value="1">新增</a-select-option>
              <a-select-option :value="2">编辑</a-select-option>
              <a-select-option :value="3">删除</a-select-option>
              <a-select-option :value="4">查询</a-select-option>
              <a-select-option :value="5">查询</a-select-option>
              <a-select-option :value="6">其他</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :xxl="6" :xl="5" :md="8" sm="2">
          <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
            <a-button type="primary" @click="getList()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table :columns="columns" :data-source="tableData" :pagination="page" @change="changeTable"
             :rowKey="record => record.id" bordered :maskClosable="false">
      <span slot="operationType" slot-scope="text"> {{ text | operatingType }} </span>
    </a-table>

    <div style="text-align: right;margin-top: 14px;">
      <a-button type="default" @click="toCancel">取消</a-button>
      <!--      <a-button type="primary" @click="toSubmit">确认</a-button>-->
    </div>
  </a-modal>

</template>

<script>
  const qs = require('qs')

  const pageSource = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  export default {

    name: 'OperateLog',
    components: {
    },
    data() {
      return {
        dialogTitle:'',
        columns: [
          {
            title: '操作人',
            dataIndex: 'createUser',
            align: 'center',
            key: 'createUser',
          },
          {
            title: '操作类型',
            dataIndex: 'operationType',
            align: 'center',
            key: 'operationType',
            scopedSlots: {customRender: 'operationType'}
          },
          {
            title: '操作内容',
            dataIndex: 'logContent',
            align: 'center',
            key: 'logContent',
          },
          {
            title: '日志生成时间',
            dataIndex: 'createTime',
            align: 'center',
            key: 'createTime',
          },

        ],
        visible: false,
        tableData: [],
        searchData: {}, // 搜索条件
        scroll: {y: 600},
        tableLoading: false, //表格loading
        page: {
          current: 1,
          pageSize: 10,
          total: 0,
          showSizeChanger: true,
          showTotal: () => `共${this.page.total}条`,
        },
        selectedRowKeys: [],
        selectedRows: [],
      }
    },
    filters: {
      operatingType (service) {
        const serviceMap = {
          1: '新增',
          2: '编辑',
          3: '删除',
          4: '查询',
          5: '审核',
          6: '其他'
        }
        return serviceMap[service]
      }
    },
    methods: {
      // 查询
      getList() {
        this.getData();
      },

      // 重置
      reset() {
        this.searchData = {}
        this.getData();
      },

      setRowData(row) {
        this.visible = true
        this.rebateId = row.id
        this.getData()
        this.dialogTitle = '【'+row.rebateName +'】操作日志';
      },

      getData() {
        let params = {
          rebateId: this.rebateId,
          pageSize: this.page.pageSize,
          pageNumber: this.page.current
        }
        const postData = Object.assign(params, this.searchData)
        this.axios
          .get(`/api/dealer/dealer/dealerRebate/logList?${qs.stringify(postData, { arrayFormat: 'repeat' })}`)
          .then((res) => {
          if (res.code === 200) {
            const {total, records} = res.body
            this.page.total = total
            this.tableData = records
          }
        })
      },

      // table分页
      changeTable(pagination) {
        this.page = pagination
        this.getData()
      },

      toCancel() {
        this.visible = false;
      }
    }

  }
</script>

<style scoped>

</style>